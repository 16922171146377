import React from 'react';
import { withKeepAlive } from 'react-next-keep-alive';

import Home from './home'

function Index (props) {
  return (
    <Home locale={props.locale} index={props.index}/>
  )
}

export default withKeepAlive(
  Index,
  'home'
);
// export const getServerSideProps = async (ctx) => {
//   // console.log('line 89', ctx.req);
//   return {props: {}}
// }
